import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import * as Style from "../css/templateStyle";
import App from "../pages/app";
import getLang from "../components/scripts/getLang";
import { useGlobal } from "reactn";
import facebookIcon from "../assets/icons/facebook.svg";
import instragramIcon from "../assets/icons/instagram.svg";
import locationIcon from "../assets/icons/icons8-near-me-100.png";
import phoneIcon from "../assets/icons/phone-symbol-of-an-auricular-inside-a-circle.svg";
import websiteIcon from "../assets/icons/web.png";
import Hero from '../components/hero'


const BarPost = ({ data }) => {
  let [english] = useGlobal("English");
  data = getLang(english, data.allContentfulEnheter);
  const {
    siteDescription,
    googleMapLink,
    adress,
    telefonnummer,
    lnkHemsida,
    facebookLnk,
    instagramLnk,
    metaTitle
  } = data[0].node;

  let phoneHref = telefonnummer
    ? telefonnummer.replace("-", "").replace(/ /g, "")
    : null;

  return (
    <App>
      <SEO description={siteDescription} title={metaTitle ? metaTitle : ''} />
      <Hero heroTema={null} data={data} img={'img2'} />

      <Style.IconContainer>
        
      {lnkHemsida ? (
          <div>
            <a href={lnkHemsida} target="_blank" rel="noopener noreferrer">
              <img src={websiteIcon} alt="websiteIcon" />
              <p>{english ? "Website" : "Hemsida"}</p>
            </a>
          </div>
        ) : null}

        {googleMapLink ? (
          <div>
            <a href={googleMapLink} target="_blank" rel="noopener noreferrer">
              <img src={locationIcon} alt="locationIcon" />
              <p>{adress}</p>
            </a>
          </div>
        ) : null}

        {telefonnummer ? (
          <div>
            <a href={"tel:" + phoneHref}>
              <img src={phoneIcon} alt="phoneIcon" />
              <p>{telefonnummer}</p>
            </a>
          </div>
        ) : null}

        {facebookLnk ? (
          <div>
            <a href={facebookLnk} target="_blank" rel="noopener noreferrer">
              <img src={facebookIcon} alt="FacebookIcon" />
              <p>{"Facebook"}</p>
            </a>
          </div>
        ) : null}
        
        {instagramLnk ? (
          <div>
            <a href={instagramLnk} target="_blank" rel="noopener noreferrer">
              <img src={instragramIcon} alt="instragramIcon" />
              <p>{"Instagram"}</p>
            </a>
          </div>
        ) : null}

      </Style.IconContainer>
    </App>
  );
};
export default BarPost;

export const pageQuery = () => {
  return graphql`
    query ContentfulBarPostBySlug($slug: String!) {
      allContentfulEnheter(filter: { slug: { eq: $slug } }) {
        edges {
          node {
            huvudBild {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
            huvudBild2 {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
            brdtext {
              brdtext
            }
            node_locale
            namn
            slug
            siteDescription
            metaTitle
            lnkHemsida
            googleMapLink
            adress
            facebookLnk
            instagramLnk
            telefonnummer
          }
        }
      }
    }
  `;
};
